import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import auth from '@feathersjs/authentication-client'

export const host = process.env.VUE_APP_BASE_API || 'http://localhost:3030'

const socket = io(host, {
  transports: ['polling', 'websocket'],
  upgrade: true,
  path: process.env.VUE_APP_SOCKET_PATH || '/socket.io'
})

const connection = socketio(socket, { timeout: 10000 })

const feathersClient = feathers()
  .configure(connection)
  .configure(auth({ storage: window.localStorage }))

feathersClient.use('orders', connection.service('orders'), {
  methods: ['find', 'create', 'update', 'delete', 'debitExtraCosts']
})

feathersClient.mixins.push(function (service) {
  service.findOne = async function (params) {
    params = params || {}
    params.query = params.query || {}
    params.query.$limit = 1
    const result = await service.find(params)
    let data = result.data || result
    return Array.isArray(data) ? data[0] : data
  }
})

export default feathersClient
